/*!

Authored by: Quinn Supplee
Email: quinn@cannacraft.com | qsupplee@gmail.com
Web: quinnsupplee.com
GitHub: github.com/quinns
Twitter: @quinns

*/

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';
import SmoothScroll from 'smooth-scroll';
import Cookies from 'js-cookie';
import Vimeo from '@vimeo/player';
import MatomoTracker from '@datapunt/matomo-tracker-js';
import '../css/libraries/age-verification.css';
import '../css/libraries/magnific-popup.css';
import '../sass/styles.scss';

const tracker = new MatomoTracker({
  urlBase: 'https://stats.cannacraft.com',
  siteId: 18
});
tracker.trackPageView();

if (!CSS.supports('(scroll-behavior: smooth)')) {
  var scroll = new SmoothScroll('a[href*="#"]');
};

let videoPlayerBkg = new Vimeo('video-wrapper', { id: '607693255', background: true, dnt: true });

// https://vimeo.com/607727165
let inlineVideoPlayer = new Vimeo('video-inline', {
  id: '607727165',
  byline: false,
  dnt: true,
  portrait: false,
  responsive: true,
  title: false
});


inlineVideoPlayer.on('play', function() {
  videoPlayerBkg.pause();
});
inlineVideoPlayer.on('pause', function() {
  videoPlayerBkg.play();
});

let cookies = Cookies.get();

$('#cookieConsent').cookieConsent({
  style: 'background-color:#333; color:#fff; position:fixed; bottom:0; width:100%; padding: 10px; z-index: 2',
});

$('.video-thumbnails .video').each(function (index, value) {
  let background_image = $(value).data('video-id') + '.jpg';
  if (typeof background_image !== 'undefined') {
    $(value).css('background-image', 'url(../img/thumbnails/' + background_image + ')');
  }
});

$('a.vimeo').magnificPopup({
  type: 'iframe',
  mainClass: 'mfp-fade',
  removalDelay: 160,
  preloader: false,
  fixedContentPos: false,
  callbacks: {
    open: function () {
      videoPlayerBkg.pause();
      inlineVideoPlayer.pause();
    },
    close: function () {
      videoPlayerBkg.play();
    }
  },
  // enable autoplay video in popup https://git.io/JEhjp
  iframe: {
    markup: '<div class="mfp-iframe-scaler">' +
      '<div class="mfp-close"></div>' +
      '<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
      '</div>',
  }
});

$('body').append($('<script src="//apps.elfsight.com/p/platform.js"></script>'));
